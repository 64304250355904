import React, { useState, useEffect } from 'react'
import {
  getPoolWhiteListedStatus,
  whitelistUsers
} from '../../../Blockchain/services/presale.service'
import { Spin, Button, notification, Tag, Alert } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { Row } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import WAValidator from 'multicoin-address-validator'
import { useAccount, useSigner } from 'wagmi';

export default function WhiteListingAddressWidget(props) {

  const { t } = useTranslation();
  const { presaleAddress, setRefreshWhitelist } = props

  const [isWhitelistStatusCheckLoading, setIsWhitelistStatusCheckLoading] = useState(false)
  const [whiteListStatus, setWhiteListStatus] = useState(false)
  const [whiteListedAddress, setWhiteListedAddress] = useState([])
  const [walletAddressCount, setWalletAddressCount] = useState(0)
  const [metamaskOpenTime, setMetamaskOpenTime] = useState(0)
  const [isMetamaskOpenMoreTime, setIsMetamaskOpenMoreTime] = useState(false)
  const [isWhiteListedExecutionLoading, setIsWhiteListedExecutionLoading] = useState(false)
  const { address: account } = useAccount();
  const { data: signer } = useSigner();

  const [duplicateAddresses, setDuplicateAddresses] = useState([])
  const [invalidAddresses, setInvalidAddresses] = useState([])
  const key = 'updatable';

  useEffect(() => {
    if (presaleAddress) {
      checkPoolWhitelistStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleAddress])

  useEffect(() => {

  }, [])

  const checkPoolWhitelistStatus = async () => {
    try {
      setIsWhitelistStatusCheckLoading(true)
      const whitelistStatusResponse = await getPoolWhiteListedStatus(presaleAddress)
      setWhiteListStatus(whitelistStatusResponse)
      setIsWhitelistStatusCheckLoading(false)
    } catch (error) {
      setWhiteListStatus(false)
      setIsWhitelistStatusCheckLoading(false)
      console.error("ERROR while checking pool whitelist status ", error)
    }
  }

  const handleWhitelistedAccountChanges = (value) => {
    let walletAddressFound = []
    const trimmedValue = value.replace(/\s/g, '');
    if (trimmedValue) {
      walletAddressFound = trimmedValue.split(",")

      //check for duplication
      let duplicates = []
      let frequencyMap = {}
      let invalidAddressesList = []

      for (let i = 0; i < walletAddressFound.length; i++) {

        var valid = WAValidator.validate(walletAddressFound[i], 'eth')
        if (!valid) {
          invalidAddressesList.push(walletAddressFound[i])
        }
        if (frequencyMap[walletAddressFound[i]] === undefined) {
          frequencyMap[walletAddressFound[i]] = 1;
        } else {
          if (duplicates.indexOf(walletAddressFound[i]) === -1) {
            duplicates.push(walletAddressFound[i]);
          }
          frequencyMap[walletAddressFound[i]] += 1;
        }
      }

      if (duplicates && duplicates?.length > 0) {
        setDuplicateAddresses(duplicates)
      } else {
        setDuplicateAddresses([])
      }

      if (invalidAddressesList && invalidAddressesList?.length > 0) {
        setInvalidAddresses(invalidAddressesList)
      } else {
        setInvalidAddresses([])
      }

      setWalletAddressCount(walletAddressFound.length || 0)

      if (walletAddressFound && walletAddressFound.length > 0) {

        const chunkSize = 15;
        // const chunkSize = 2;
        if (walletAddressFound.length > chunkSize) {
          setIsMetamaskOpenMoreTime(true)

          let timesHaveToOpenMetamask = Math.floor(walletAddressFound.length / chunkSize);
          const remainder = Math.floor(walletAddressFound.length % chunkSize);

          if (remainder > 0) {
            timesHaveToOpenMetamask = timesHaveToOpenMetamask + 1
            setMetamaskOpenTime(timesHaveToOpenMetamask)
          } else {
            setMetamaskOpenTime(timesHaveToOpenMetamask)
          }
        }

        for (let i = 0; i < walletAddressFound.length; i++) {
          setWhiteListedAddress(oldArray => [...oldArray, walletAddressFound[i]]);
        }
        setWhiteListedAddress(walletAddressFound)
      } else {
        setWhiteListedAddress([])
        setWalletAddressCount(0)
      }
    } else {
      setWhiteListedAddress([])
      setWalletAddressCount(0)
    }
  }

  const handleSetWhitelistedWalletAddress = async () => {
    try {
      if (!account) {
        notification['error']({
          key,
          message: t('Authentication Error'),
          description:
            t('Please connect your wallet to proceed'),
        });
        setIsWhiteListedExecutionLoading(false)
        return
      }

      if (walletAddressCount <= 0) {
        notification['error']({
          key,
          message: t('Input Error'),
          description:
            t('No wallet address found, please review.'),
        });
        setIsWhiteListedExecutionLoading(false)
        return
      }

      if (walletAddressCount > 2) {
        setIsWhiteListedExecutionLoading(true)
        const chunkSize = 15;
        // const chunkSize = 2;
        let metamaskOpened = 0
        for (let i = 0; i < whiteListedAddress.length; i += chunkSize) {
          const chunk = whiteListedAddress.slice(i, i + chunkSize);

          const whitelistUserResponse = await whitelistUsers(presaleAddress, chunk, signer)
          if (whitelistUserResponse) {
            metamaskOpened = metamaskOpened + 1
            setMetamaskOpenTime(metamaskOpenTime - 1)
            notification['success']({
              key,
              message: t(`Set ${metamaskOpened} Whitelisting Complete`),
              description:
                t(`You have successfully whitelisted set ${metamaskOpened} wallet address`),
            });

          }
        }
        setIsMetamaskOpenMoreTime(false)
        setIsWhiteListedExecutionLoading(false)
        setWhiteListedAddress('')
        setRefreshWhitelist(true)
        setWalletAddressCount(0)
        return
      }
      setIsWhiteListedExecutionLoading(true)
      const whitelistUserResponse = await whitelistUsers(presaleAddress, whiteListedAddress, signer)
      if (whitelistUserResponse) {
        notification['success']({
          key,
          message: t(`Whitelisting Complete`),
          description:
            t(`Successfully whitelisted wallet address/s.`),
        });

        setRefreshWhitelist(true)
        setWhiteListedAddress('')
        setWalletAddressCount(0)
      }
      setIsWhiteListedExecutionLoading(false)


    } catch (error) {
      setIsWhiteListedExecutionLoading(false)
      console.error("ERROR while executing token whitelisting ", error)
      notification['error']({
        key,
        message: t('Transaction Execution Error'),
        description: error,
      });

    }
  }

  return (
    <div>

      {
        isWhitelistStatusCheckLoading ? (
          <div className='d-flex justify-content-center'>
            <Spin size='small' />
          </div>
        ) : (
          <>
            {
              whiteListStatus ? (
                <>
                  <Row className="mt-4">
                    <span className='input-label'>
                      {t('Whitelist user wallet address')}
                    </span>
                  </Row>

                  {
                    whiteListedAddress && whiteListedAddress.map((address, index) => (
                      <Tag className="text-break" key={index} color="gold">{address}</Tag>
                    ))
                  }

                  <Row style={{ padding: '0 10px' }} className="mt-2">
                    <TextArea
                      rows={10}
                      onChange={e => handleWhitelistedAccountChanges(e.target.value)}
                      value={whiteListedAddress}
                      placeholder="Enter comma separated Whitelisting Addresses here"
                    />
                  </Row>

                  <Row style={{ padding: '0 10px' }} className="mt-2 mb-2">
                    {
                      duplicateAddresses && duplicateAddresses.length > 0 &&
                      <Alert message={`Found ${duplicateAddresses.length} duplicate addresses`} type="warning" showIcon />
                    }
                  </Row>
                  <Row style={{ padding: '0 10px' }} className="mt-2 mb-2">
                    {
                      invalidAddresses && invalidAddresses.length > 0 &&
                      <Alert message={`Found ${invalidAddresses.length} invalid addresses`} type="warning" showIcon />
                    }
                  </Row>
                  <Row className="mt-2 mb-2">
                    <span className='wallet-count-metadata'>{t('We have found')} {walletAddressCount} {t('address')}</span>
                    {
                      isMetamaskOpenMoreTime ? (
                        <span>Metamask will open {metamaskOpenTime} times</span>
                      ) : (<></>)
                    }

                    <div className='contribution-button-container mt-2'>
                      <Button
                        loading={isWhiteListedExecutionLoading}
                        onClick={handleSetWhitelistedWalletAddress}
                        disabled={walletAddressCount === 0 || invalidAddresses.length > 0 || duplicateAddresses.length > 0}
                        type="primary">
                        {t('Set Whitelisted Accounts')}
                      </Button>
                    </div>
                  </Row>
                </>
              ) : (<></>)
            }

          </>
        )
      }
    </div>
  )
}
