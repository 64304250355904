import React, { useState } from 'react'
import './HeaderComponent.css'
import { Link } from 'react-router-dom'
import { Row } from 'reactstrap'
import { Drawer } from 'antd'
import MenuComponent from '../Menu/MenuComponent'
import { MenuOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { useDispatch } from "react-redux";
import { change } from '../../Redux/SidemenuMargin';
import MainLogo from '../../images/logo_v2.png'
import WebsiteSettings from '../WebsiteSettings/WebsiteSettings'
import WalletConnectWidget from '../WalletConnectWidget/WalletConnectWidget'

function Header() {

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const dispatch = useDispatch();
  const [hamburgerClass, setHamburgerClass] = useState('hamburger')


  const showDrawer = () => {
    setShowMobileMenu(true);
  };

  const closeDrawer = () => {
    setShowMobileMenu(false);
  };

  const handleSideMenu = () => {
    if (hamburgerClass === "hamburger") {
      setHamburgerClass("hamburger is-active")
    } else {
      setHamburgerClass("hamburger")
    }
    dispatch(change())
  }

  return (
    <div className="fixed-top bg-blue">
      <Row>
        <div className='d-flex justify-content-between'>
          <div>

            <div className='d-flex'>
              <MenuOutlined style={{ fontSize: '18px', marginRight: '5px' }} className='mobile-menu my-auto' onClick={showDrawer} />

              <div className="hamburger-menu-margin three hamburger-col hide-on-mobile my-auto">
                <div className={hamburgerClass} id="hamburger-10" onClick={() => handleSideMenu()}>
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>

              <div>
                <Link to="/"><img src={MainLogo} alt="kingsale_main_logo" className='noselect kingsale_main_logo' /></Link>
              </div>
            </div>
          </div>

          <div className='my-auto d-flex'>
            <WalletConnectWidget />
          </div>
        </div>
      </Row>

      <Drawer
        placement="left"
        open={showMobileMenu}
        closable={false}
        width="250px"
        style={{visibility: showMobileMenu ? 'visible' : 'hidden'}}
      >
        <div style={{ marginLeft: '-8px' }}>

          <div className='pb-1' style={{ marginTop: '-17px' }}>
            <DoubleLeftOutlined style={{ fontSize: '18px', marginRight: '5px' }} className='mobile-menu-svg' onClick={() => closeDrawer()} />

            <img src={MainLogo} alt="kingsale_main_logo" className='kingsale_main_logo' style={{ marginLeft: '' }} />
          </div>

          <MenuComponent
            setShowMobileMenu={setShowMobileMenu}
          />

          <div>
            <WebsiteSettings />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Header