import './App.css';
import Layoutview from './Layoutview';
import { BrowserRouter as Router } from 'react-router-dom';

// rainbowkit
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bscTestnet, bsc } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

function App() {
  const { chains, provider } = configureChains(
    [bsc, bscTestnet],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: 'KingSale',
    projectId: 'fe2161c0650c2fa637191137b414003a',
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: '#e6bd4f',
          accentColorForeground: 'black',
          borderRadius: 'large',
          fontStack: 'system',
          overlayBlur: 'small',
        })}
      >
        <Router>
          <div className="App">
            <Layoutview />
          </div>
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
